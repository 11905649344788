import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { graphql } from 'gatsby';
import firebase from '../util/firebase';

// import assets
import chevron from '../images/icons/list-chevron.png';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../util/theme';

// import components
import Layout from '../components/Layout';
import { Grid } from '../components/Grid';

const HelpBody = styled.div`
  padding: 20px 0 0 0;

  @media (min-width: ${getBreakpoint('md')}) {
    padding: 0 0 125px 0;
  }

  h2 {
    color: #2d536f;
    margin: 25px 0;
    padding: 25px 0 15px 0;
  }

  p {
    padding: 0 0 20px 0;
  }

  ul {
    list-style: url(${chevron}) inside;

    li {
      margin: 0 0 15px 0;

      a {
        font-weight: 900;
        color: ${getColor('primaryBlue')};
        font-size: 1.8rem;
        font-weight: ${getFontWeight('medium')};
        text-decoration: none;

        padding: 0 10px;

        &:hover {
          color: ${getColor('accentGreen')};
        }
      }
    }
  }
`;

const Help = ({ data }) => {
  const [jobAids, setJobAids] = useState([]);

  const getJobAidsFromFireBase = () => {
    const db = firebase.firestore();
    db.collection('jobAids')
      .get()
      .then(data => {
        const jobAids = data.docs.map(doc => doc.data());
        setJobAids(jobAids);
      });
  };

  useEffect(() => {
    getJobAidsFromFireBase();
  }, []);

  console.log(jobAids);
  const {
    allHelpJson: { edges },
  } = data;

  const drawHelp = items => {
    return jobAids.map(item => {
      const { id, name, url } = item;
      return (
        <li key={id}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {name}
          </a>
        </li>
      );
    });
  };

  return (
    <Layout>
      <Grid>
        <HelpBody>
          <h2>Marketing Portal Help</h2>
          <p>
            References are available below that will assist you in fulfilling
            your marketing needs. We have created a video that will walk you
            through accessing, downloading, and creating a flyer from start to
            finish. We also have the Advertising and Marketing Policy for your
            reference as well as a compliance cheat sheet to ensure all of your
            marketing is compliant with federal and company guidelines.
          </p>
          <ul>{drawHelp(edges)}</ul>
        </HelpBody>
      </Grid>
    </Layout>
  );
};

export default Help;

export const GET_HELP_DATA = graphql`
  query GET_HELP_DATA {
    allHelpJson {
      edges {
        node {
          id
          text
          url
        }
      }
    }
  }
`;
